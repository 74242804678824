<template>
  <div>
    <template v-for="item of items">
      {{ item.quantity }} X {{ item.paymentToken.name }}
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
}
</script>
